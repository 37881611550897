import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ page: Component, auth, role, course, ...props }) => {
  let isLogged = false;
  if (auth.uid !== null && auth.token) {
    if (role) {
      if (auth.roles.includes(role)) {
        isLogged = true;
      } else {
        isLogged = false;
      }
    } else {
      isLogged = true;
    }
  }

  if (course) {
    if (course.admin.length === 0) {
      return <Redirect to="/unauthorized" />;
    }
  }
  return (
    <Route
      {...props}
      render={(props) =>
        isLogged ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default PrivateRoute;
