import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackBar } from '../../store/Nav/NavActionsCreator';
import { Slide } from '@mui/material';

const TransitionUp = (props) => {
  return <Slide {...props} direction="up" />;
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackComponent = ({ open, setOpen, severity, message }) => {
  const disptach = useDispatch();
  const snackbar = useSelector((state) => state.nav.snackbar);
  // open, setOpen, severity, message

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    disptach(setSnackBar(null));
  };
  return (
    <div>
      {snackbar !== null && snackbar.open ? (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleClose}
          TransitionComponent={TransitionUp}
        >
          <Alert
            onClose={handleClose}
            severity={snackbar.severity ? snackbar.severity : 'info'}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      ) : (
        ''
      )}
    </div>
  );
};

export default SnackComponent;
