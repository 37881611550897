import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import AuthReducer from './Auth/AuthReducer';
import NavReducer from './Nav/NavReducer';

const reducers = combineReducers({
  auth: AuthReducer,
  nav: NavReducer,
});

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
