export const LOADING = 'LOADING';
export const SOCKET = 'SOCKET';
export const SNACKBAR = 'SNACKBAR';
export const NOTIFICATIONS = 'NOTIFICATIONS';
export const NOTIFICATIONSICON = 'NOTIFICATIONSICON';
export const LISTNOTIFICATIONS = 'LISTNOTIFICATIONS';
export const CHANGEREADNOTIFICATION = 'CHANGEREADNOTIFICATION';
export const USERS = 'USERS';
export const ROLES = 'ROLES';
export const COURSESSEMESTERS = 'COURSESSEMESTERS';
export const COURSES = 'COURSES';
export const COURSE = 'COURSE';
export const COURSEVIDEOUSERMODULE = 'COURSEVIDEOUSERRMODULE';
export const COURSEVIDEOUSERBLOCK = 'COURSEVIDEOUSERBLOCK';
export const COURSEVIDEOUSERSECTION = 'COURSEVIDEOUSERSECTION';
