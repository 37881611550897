import {
  CHANGEREADNOTIFICATION,
  COURSE,
  COURSESSEMESTERS,
  LISTNOTIFICATIONS,
  LOADING,
  NOTIFICATIONS,
  NOTIFICATIONSICON,
  ROLES,
  SNACKBAR,
  SOCKET,
  USERS,
  COURSEVIDEOUSERMODULE,
  COURSEVIDEOUSERBLOCK,
  COURSEVIDEOUSERSECTION,
} from './NavActions';

export const showHideLoading = (data) => ({
  type: LOADING,
  data: data,
});

export const setSocket = (data) => ({
  type: SOCKET,
  data: data,
});

export const setSnackBar = (data) => ({
  type: SNACKBAR,
  data: data,
});

export const setNotifications = (data) => ({
  type: NOTIFICATIONS,
  data: data,
});

export const setNotificationsIcon = (data) => ({
  type: NOTIFICATIONSICON,
  data: data,
});

export const setListNotifications = (data) => ({
  type: LISTNOTIFICATIONS,
  data: data,
});

export const changeReadNotification = (data) => ({
  type: CHANGEREADNOTIFICATION,
  data: data,
});

export const setUsers = (data) => ({
  type: USERS,
  data: data,
});

export const setRoles = (data) => ({
  type: ROLES,
  data: data,
});

export const setCoursesSemesters = (data) => ({
  type: COURSESSEMESTERS,
  data: data,
});

export const setCourses = (data) => ({
  type: COURSESSEMESTERS,
  data: data,
});

export const setCourse = (data) => ({
  type: COURSE,
  data: data,
});

export const setCourseVideoUserModule = (data) => ({
  type: COURSEVIDEOUSERMODULE,
  data: data,
});

export const setCourseVideoUserBlock = (data) => ({
  type: COURSEVIDEOUSERBLOCK,
  data: data,
});

export const setCourseVideoUserSection = (data) => ({
  type: COURSEVIDEOUSERSECTION,
  data: data,
});
